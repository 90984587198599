body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #f0f2f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #1890ff;
  color: white;
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header h1 {
  font-size: 18px;
  margin: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  overflow-y: auto;
  
}

.search-container {
  display: flex;
  width: 100%;
  max-width: 300px;
}

input {
  flex: 1;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button {
  padding: 10px 15px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
}

button:hover {
  background-color: #40a9ff;
}

button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}
.parsed-link-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.parsed-link-button:hover {
  background-color: #0056b3;
}
.order-info {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
}

.order-info h2 {
  color: #1890ff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 8px;
  position: relative; /* 添加这行 */
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
}

.order-item:last-child {
  border-bottom: none;
}

.order-label {
  font-weight: bold;
  color: #555;
  font-size: 12px;
}

.order-value {
  color: #1890ff;
  font-size: 12px;
}

.order-value.highlight {
  font-size: 14px;
  font-weight: bold;
  color: #52c41a;
}

.order-value.status {
  padding: 3px 8px;
  border-radius: 12px;
  font-weight: bold;
}

.order-value.status.active {
  background-color: #f6ffed;
  color: #52c41a;
}

.order-value.status.inactive {
  background-color: #fff2f0;
  color: #ff4d4f;
}

.message {
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  font-size: 14px;
}

.footer {
  background-color: #f0f2f5;
  padding: 5px;
  text-align: center;
  color: #888;
  font-size: 10px;
}

.order-button {
  background-color: #52c41a;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  margin-top: 10px;
}

.order-button:hover {
  background-color: #73d13d;
}

.order-button.highlight {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.7);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 18px;
  }

  .search-container, .order-info, .invitation-info, .message, .order-button {
    max-width: 90%; /* 在小屏幕上使用百分比宽度 */
  }

  input, button {
    font-size: 14px;
  }

  .order-info h2 {
    font-size: 16px;
  }

  .order-label, .order-value {
    font-size: 13px;
  }

  .order-value.highlight {
    font-size: 15px;
  }

  .message {
    font-size: 13px;
  }

  .footer {
    font-size: 11px;
  }
}

/* 在文件末尾添加以下样式 */

.invitation-button {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  transition: background-color 0.3s;
}

.invitation-button:hover {
  background-color: #40a9ff;
}

.invitation-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.invitation-info {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 300px; /* 统一最大宽度 */
  margin-top: 20px;
}

.invitation-info h3 {
  color: #1890ff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 8px;
}

.invitation-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
}

.invitation-item:last-child {
  border-bottom: none;
}

.invitation-label {
  font-weight: bold;
  color: #555;
  font-size: 12px;
}

.invitation-value {
  color: #1890ff;
  font-size: 12px;
  text-align: right;
  word-break: break-all;
}

/* 新增：队长球球号的特殊样式 */
.invitation-value .captain-id {
  font-weight: bold;
  color: #ff4d4f;
  background-color: #fff2f0;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 5px;
}

.invitation-link {
  color: #1890ff;
  text-decoration: none;
  word-break: break-all;
}

.invitation-link:hover {
  text-decoration: underline;
}

.invitation-message {
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
}

/* ... 后面的样式保持不变 */

.parsed-link-button {
  background-color: #52c41a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.parsed-link-button:hover {
  background-color: #73d13d;
}

/* ... 后面的样式保持不变 */

.qr-code-button {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.qr-code-button:hover {
  background-color: #40a9ff;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

/* ... 面的样式保持不变 ... */

.countdown-message {
  color: #1890ff;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.contact-info {
  color: #ff4d4f;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}

/* ... 后面的样式保持不变 ... */

@media (max-height: 600px) {
  .header h1 {
    font-size: 16px;
  }

  .order-info h2, .invitation-info h3 {
    font-size: 14px;
  }

  .order-label, .order-value, .invitation-label, .invitation-value {
    font-size: 11px;
  }

  .order-value.highlight {
    font-size: 13px;
  }

  .invitation-button, .parsed-link-button, .order-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .footer {
    font-size: 9px;
  }
}

/* 修改 .search-container, .order-info, .invitation-info, .message 的最大宽度 */
.search-container,
.order-info,
.invitation-info,
.message {
  width: 100%;
  max-width: 300px; /* 统一最大宽度 */
  margin-left: auto;
  margin-right: auto;
}

/* 修改 .order-button 的最大宽度以保持一致 */
.order-button {
  width: 100%;
  max-width: 300px; /* 与上面保持一致 */
  margin-left: auto;
  margin-right: auto;
}

/* 修改媒体查询中的最大宽度 */
@media (max-width: 480px) {
  .search-container,
  .order-info,
  .invitation-info,
  .message,
  .order-button {
    max-width: 90%; /* 在小屏幕上使用百分比宽度 */
  }
  /* ... 其他媒体查询样式保持不变 ... */
}

.help-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}

.help-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.help-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
}

.help-content h3 {
  margin-top: 0;
  color: #1890ff;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 15px;
}

.help-content ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.help-content li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s;
}

.close-icon:hover {
  color: #1890ff;
}

/* ... 其他样式保持不变 ... */

.qr-code-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.qr-code-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1.5;
}
.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.qr-code-instruction {
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

.qr-code-wrapper {
  display: flex;
  align-items: center;
}

.qr-code-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1.5;
}

.free-trial {
  color: #28a745;
  font-size: 0.9em;
  margin-left: 5px;
}

.order-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.invitation-button, .refund-button {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  height: 36px;  /* 统一按钮高度 */
  transition: background-color 0.3s;
}

.refund-button {
  background-color: #ff4d4f;
}

.refund-button:hover {
  background-color: #ff7875;
}

.invitation-button:hover {
  background-color: #40a9ff;
}

.invitation-button:disabled, .refund-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.refund-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.refund-tip {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}